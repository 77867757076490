import { defineStore } from "pinia";
import userService from "../services/user.service";
import setupService from "../services/setup.service";
import Swal from "sweetalert2";
import { useMainStore } from "./main";
const mainStore = useMainStore();

export const useAdminStore = defineStore("admin", {
  state: () => {
    return {
      loading: false,
      pageLoading: false,
      status: {
        type: "",
        class: "",
        message: "",
      },

      users: [],
      user: {},

      roles: [],
      role: {},

      rates: [],
      rate: {},

      parkingTypes: [],
      parkingType: {},

      voyageTypes: [],
      voyageType: {},

      coverTypes: [],
      coverType: {},

      natureOfCargos: [],
      natureOfCargo: {},

      conveyances: [],
      conveyance: {},

      allBrokers: [],
    };
  },

  actions: {
    error(message) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
        confirmButtonColor: "#990100",
        footer: '<a href="">Report issue</a>',
      });
    },

    async success(message) {
      await Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        confirmButtonColor: "#990100",
      });
    },

    async getAllUsers() {
      this.pageLoading = true;
      try {
        const response = await userService.getUsers();
        this.pageLoading = false;
        this.users = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },
    async getAllBrokers() {
      //this.pageLoading = true
      try {
        const response = await userService.getBrokers();
        //this.pageLoading = false
        this.allBrokers = response.data;
      } catch (error) {
        //this.pageLoading = false
        this.error(error.message);
      }
    },

    async getUser(id) {
      this.pageLoading = true;
      try {
        const response = await userService.getUserById(id);
        this.pageLoading = false;
        this.user = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async createUser(payload) {
      this.loading = true;
      try {
        const response = await userService.createUser(
          payload,
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("User Created Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async updateUser(payload) {
      this.loading = true;
      try {
        const response = await userService.updateUser(
          {
            id: payload.id,
            name: payload.name,
            email: payload.email,
            userName: payload.userName,
            phoneNumber: payload.phoneNumber,
            status: payload.status,
            role: payload.role,
            mktStaff: payload.mktStaff,
          },
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("User Details Updated Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async getAllRoles() {
      this.pageLoading = true;
      try {
        const response = await setupService.getRoles();
        this.pageLoading = false;
        this.roles = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async getRole(id) {
      this.pageLoading = true;
      try {
        const response = await setupService.getRoleById(id);
        this.pageLoading = false;
        this.role = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async createRole(payload) {
      this.loading = true;
      try {
        const response = await setupService.createRole(
          payload,
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("Role Created Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async updateRole(payload) {
      this.loading = true;
      try {
        const response = await setupService.updateRole(
          {
            id: payload.id,
            name: payload.name,
          },
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("Role Updated Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async getAllRates() {
      this.pageLoading = true;
      try {
        const response = await setupService.getRates();
        this.pageLoading = false;
        this.rates = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async getRate(id) {
      this.pageLoading = true;
      try {
        const response = await setupService.getRateById(id);
        this.pageLoading = false;
        this.rate = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async bulkCreateRate(payload) {
      this.loading = true;
      try {
        const response = await setupService.createRate(
          payload,
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          console.error(response.data.message);
          return;
        }
        this.loading = false;
        //   await this.success("Rate Created Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        console.error(error.message);
      }
    },
    async createRate(payload) {
      this.loading = true;
      try {
        const response = await setupService.createRate(
          payload,
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("Rate Created Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async updateRate(payload, party) {
      this.loading = true;
      try {
        const response = await setupService.updateRate(
          {
            id: payload.id,
            name: payload.name,
            status: payload.status,
            typeofCovers: payload.typeofCovers,
            cover: payload.cover,
            voyageType: payload.voyageType,
            partyId: party.partyId,
            party: party.party,
          },
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("Rate Updated Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async getAllParkingTypes() {
      this.pageLoading = true;
      try {
        const response = await setupService.getParkingTypes();
        this.pageLoading = false;
        this.parkingTypes = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async getParkingType(id) {
      this.pageLoading = true;
      try {
        const response = await setupService.getParkingTypeById(id);
        this.pageLoading = false;
        this.parkingType = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async createParkingType(payload) {
      this.loading = true;
      try {
        const response = await setupService.createParkingType(
          payload,
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("ParkingType Created Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async updateParkingType(payload) {
      this.loading = true;
      try {
        const response = await setupService.updateParkingType(
          {
            id: payload.id,
            name: payload.name,
          },
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("ParkingType Updated Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async getAllVoyageTypes() {
      this.pageLoading = true;
      try {
        const response = await setupService.getVoyageTypes();
        this.pageLoading = false;
        this.voyageTypes = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async getVoyageType(id) {
      this.pageLoading = true;
      try {
        const response = await setupService.getVoyageTypeById(id);
        this.pageLoading = false;
        this.voyageType = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async createVoyageType(payload) {
      this.loading = true;
      try {
        const response = await setupService.createVoyageType(
          payload,
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("VoyageType Created Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async updateVoyageType(payload) {
      this.loading = true;
      try {
        const response = await setupService.updateVoyageType(
          {
            id: payload.id,
            name: payload.name,
          },
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("VoyageType Updated Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async getAllCoverTypes() {
      this.pageLoading = true;
      try {
        const response = await setupService.getCoverTypes();
        this.pageLoading = false;
        this.coverTypes = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async getCoverType(id) {
      this.pageLoading = true;
      try {
        const response = await setupService.getCoverTypeById(id);
        this.pageLoading = false;
        this.coverType = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async createCoverType(payload) {
      this.loading = true;
      try {
        const response = await setupService.createCoverType(
          payload,
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("CoverType Created Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async updateCoverType(payload) {
      this.loading = true;
      try {
        const response = await setupService.updateCoverType(
          {
            id: payload.id,
            name: payload.name,
          },
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("CoverType Updated Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async getAllNatureOfCargos() {
      this.pageLoading = true;
      try {
        const response = await setupService.getNatureOfCargos();
        this.pageLoading = false;
        this.natureOfCargos = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async getNatureOfCargo(id) {
      this.pageLoading = true;
      try {
        const response = await setupService.getNatureOfCargoById(id);
        this.pageLoading = false;
        this.natureOfCargo = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async createNatureOfCargo(payload) {
      this.loading = true;
      try {
        const response = await setupService.createNatureOfCargo(
          payload,
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("NatureOfCargo Created Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async updateNatureOfCargo(payload) {
      this.loading = true;
      try {
        const response = await setupService.updateNatureOfCargo(
          {
            id: payload.id,
            name: payload.name,
          },
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("NatureOfCargo Updated Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async getAllConveyances() {
      this.pageLoading = true;
      try {
        const response = await setupService.getConveyances();
        this.pageLoading = false;
        this.conveyances = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async getConveyance(id) {
      this.pageLoading = true;
      try {
        const response = await setupService.getConveyanceById(id);
        this.pageLoading = false;
        this.conveyance = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async createConveyance(payload) {
      this.loading = true;
      try {
        const response = await setupService.createConveyance(
          payload,
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("Conveyance Created Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async updateConveyance(payload) {
      this.loading = true;
      try {
        const response = await setupService.updateConveyance(
          {
            id: payload.id,
            name: payload.name,
          },
          mainStore.user.email
        );
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;
        await this.success("Conveyance Updated Successfully");
        return "success";
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },
  },
  getters: {
    brokers: (state) =>
      state.users.filter((user) => {
        return user.role == "Broker";
      }),
  },
});
