import $axios from "../plugins/axios";
// import { multipart } from "../plugins/axios";

export default class {
  //CUSTOMER
  static buyMarineCustomer = async (payload) => {
    return await $axios().post("/buyMarineCustomer", { ...payload });
  };

  //BROKER//

  static buyMarine = async (payload, party, partyId) => {
    return await $axios().post("/buyMarine", { ...payload, party, partyId });
  };

  static uploadDocument = async (document) => {
    return await $axios("formdata").post("/UploadFile", document);
  };

  static getDocument = async (fileName) => {
    return await $axios().get(`/DownloadFile?fileName=${fileName}`);
  };

  static buyAdditionalMarine = async (payload, party, partyId) => {
    return await $axios().post("/buyAdditionalMarine", {
      ...payload,
      party,
      partyId,
    });
  };

  static getMarineDetails = async (CertificateNo) => {
    return await $axios().get(`/GetMarineDetailByCertificate${CertificateNo}`);
  };

  static getBookedBusinesses = async (id) => {
    return await $axios().get(`/GetMarineDetails${id}`);
  };

  static getRateByBrokerId = async (id, coverType, cover, voyageType) => {
    return await $axios().get(
      `/getRateByPartyId${id}/${coverType}/${cover}/${voyageType}`
    );
  };

  static getBrokerMarketers = async (Id) => {
    return await $axios().get(`/getAllBrokerMarketerDD${Id}`);
  };

  //BROKER//

  //AGENT//

  static buyMarineAgent = async (payload, party, partyId) => {
    return await $axios().post("/buyMarineAgent", {
      ...payload,
      party,
      partyId,
    });
  };

  static buyAdditionalMarineAgent = async (payload, party, partyId) => {
    return await $axios().post("/buyAdditionalMarineAgent", {
      ...payload,
      party,
      partyId,
    });
  };

  static getMarineDetailsAgent = async (CertificateNo) => {
    return await $axios().get(
      `/GetMarineDetailByCertificateAgent${CertificateNo}`
    );
  };

  static getAgentBookedBusinesses = async (id) => {
    return await $axios().get(`/GetMarineDetailsAgent/${id}/Agent`);
  };

  static getAgentRate = async (id, coverType, cover, voyageType) => {
    return await $axios().get(
      `/getRateByPartyId${id}/${coverType}/${cover}/${voyageType}`
    );
  };

  //AGENT//

  static makePayment = async (payload, party, partyId) => {
    return await $axios().post("/MakePayment", { ...payload, party, partyId });
  };

  static getExchangeRates = async () => {
    return await $axios().get("/getExchangeRate");
  };

  static getMarketers = async () => {
    return await $axios().get("/GetMarketer");
  };

  static getStates = async () => {
    return await $axios().get("/State");
  };

  static getLga = async (id) => {
    return await $axios().get(`/LGA${id}`);
  };

  static getCoverScopes = async () => {
    return await $axios().get(`/GetCoverScope`);
  };

  static getEndorsementOptions = async () => {
    return await $axios().get(`/GetEndorsementOptions`);
  };

  static getCertificateTypes = async () => {
    return await $axios().get(`/CertificateType`);
  };

  static getMarketingStaff = async () => {
    return await $axios().get(`/GetMarketer`);
  };

  static getCurrency = async () => {
    return await $axios().get(`/getExchangeRate`);
  };

  static getOccupation = async () => {
    return await $axios().get(`/GetOccupation`);
  };

  static getIndustries = async () => {
    return await $axios().get(`/GetIndustry`);
  };

  static getAdditionalClauses = async (coverType) => {
    return await $axios().get(`/GetAdditionalClause${coverType}`);
  };

  static getClient = async (PolicyNo) => {
    return await $axios().get(`/GetInsuredClient${PolicyNo}`);
  };
}
