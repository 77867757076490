import $axios from '../plugins/axios';

export default class {
    static getRoles = async () => {
        return await $axios().get('/getAllRole');
    };

    static createRole = async (payload, submittedBy) => {
        return await $axios().post('/addRole', { ...payload, submittedBy });
    };

    static getRoleById = async (id) => {
        return await $axios().get(`/getRoleById${id}`);
    };

    static updateRole = async (payload, submittedBy) => {
        return await $axios().post('/updateRole', { ...payload, submittedBy });
    };


    static getRates = async () => {
        return await $axios().get('/getAllRate');
    };

    static createRate = async (payload, submittedBy) => {
        return await $axios().post('/addRate', { ...payload, submittedBy });
    };

    static getRateById = async (id) => {
        return await $axios().get(`/getRateById${id}`);
    };

    static updateRate = async (payload, submittedBy) => {
        return await $axios().post('/updateRate', { ...payload, submittedBy });
    };



    static getParkingTypes = async () => {
        return await $axios().get('/getAllParkingType');
    };

    static createParkingType = async (payload, submittedBy) => {
        return await $axios().post('/addParkingType', { ...payload, submittedBy });
    };

    static getParkingTypeById = async (id) => {
        return await $axios().get(`/getParkingTypeById${id}`);
    };

    static updateParkingType = async (payload, submittedBy) => {
        return await $axios().post('/updateParkingType', { ...payload, submittedBy });
    };



    static getVoyageTypes = async () => {
        return await $axios().get('/getAllVoyageType');
    };

    static createVoyageType = async (payload, submittedBy) => {
        return await $axios().post('/addVoyageType', { ...payload, submittedBy });
    };

    static getVoyageTypeById = async (id) => {
        return await $axios().get(`/getVoyageTypeById${id}`);
    };

    static updateVoyageType = async (payload, submittedBy) => {
        return await $axios().post('/updateVoyageType', { ...payload, submittedBy });
    };



    static getCoverTypes = async () => {
        return await $axios().get('/getAllTypeofCover');
    };

    static createCoverType = async (payload, submittedBy) => {
        return await $axios().post('/addTypeofCover', { ...payload, submittedBy });
    };

    static getCoverTypeById = async (id) => {
        return await $axios().get(`/getTypeofCoverById${id}`);
    };

    static updateCoverType = async (payload, submittedBy) => {
        return await $axios().post('/updateTypeofCover', { ...payload, submittedBy });
    };



    static getNatureOfCargos = async () => {
        return await $axios().get('/getAllNatureofCargo');
    };

    static createNatureOfCargo = async (payload, submittedBy) => {
        return await $axios().post('/addNatureofCargo', { ...payload, submittedBy });
    };

    static getNatureOfCargoById = async (id) => {
        return await $axios().get(`/getNatureofCargoById${id}`);
    };

    static updateNatureOfCargo = async (payload, submittedBy) => {
        return await $axios().post('/updateNatureofCargo', { ...payload, submittedBy });
    };



    static getConveyances = async () => {
        return await $axios().get('/getAllConveyance');
    };

    static createConveyance = async (payload, submittedBy) => {
        return await $axios().post('/addConveyance', { ...payload, submittedBy });
    };

    static getConveyanceById = async (id) => {
        return await $axios().get(`/getConveyanceById${id}`);
    };

    static updateConveyance = async (payload, submittedBy) => {
        return await $axios().post('/updateConveyance', { ...payload, submittedBy });
    };
}

