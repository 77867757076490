import { defineStore } from "pinia";
import marineService from "../services/marine.service";
import Swal from "sweetalert2";
import router from "../router";
import { useMainStore } from "./main";
const mainStore = useMainStore();

export const useMarineStore = defineStore("marine", {
  state: () => {
    return {
      loading: false,
      componentLoading: false,
      componentLoadingMessage: "",
      pageLoading: false,
      status: {
        type: "",
        class: "",
        message: "",
      },

      rates: [],
      brokerRate: 0,
      marketers: [],
      brokerMarketers: [],
      states: [],
      bookedBusinesses: [],
      singleBusiness: { additionalMarine: [] },
      lgas: [],
      coverScopes: [],
      endorsementOptions: [],
      certificateTypes: [],
      currencyRates: [],
      occupations: [],
      industries: [],
      additionalClauses: [],

      marineData: {},
      marineMetaData: {},

      steps: 1,
    };
  },

  actions: {
    error(message) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
        confirmButtonColor: "#990100",
        footer: '<a href="">Report issue</a>',
      });
    },

    warning(message) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: message,
        confirmButtonColor: "#990100",
        // footer: '<a href="">Report issue</a>'
      });
    },

    incrementStep(num) {
      this.steps = num;
    },

    async success(message) {
      await Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        confirmButtonColor: "#990100",
      });
    },

    async getExchangeRates() {
      //this.componentLoading = true
      try {
        const response = await marineService.getExchangeRates();
        this.componentLoading = false;
        this.rates = response.data;
      } catch (error) {
        this.componentLoading = false;
        this.error(error.message);
      }
    },

    async getBrokerRate(id, coverType, cover, voyageType) {
      //this.pageLoading = true
      try {
        const response = await marineService.getRateByBrokerId(
          id,
          coverType,
          cover,
          voyageType
        );
        //this.pageLoading = false
        this.brokerRate = response.data;
        return this.brokerRate;
      } catch (error) {
        //this.pageLoading = false
        this.error(error.message);
      }
    },

    async getMarketers() {
      //this.componentLoading = true
      try {
        const response = await marineService.getMarketers();
        this.componentLoading = false;
        this.marketers = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async getBrokerMarketers() {
      //this.componentLoading = true
      try {
        const response = await marineService.getBrokerMarketers(
          mainStore.user.brokerId
        );
        this.componentLoading = false;
        this.brokerMarketers = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async getStates() {
      //this.componentLoading = true
      try {
        const response = await marineService.getStates();
        this.componentLoading = false;
        this.states = response.data;
      } catch (error) {
        this.componentLoading = false;
        this.error(error.message);
      }
    },

    async getLga(id) {
      try {
        const response = await marineService.getLga(id);
        this.lgas = response.data;
      } catch (error) {
        this.error(error.message);
      }
    },

    async getCoverScopes() {
      try {
        const response = await marineService.getCoverScopes();
        this.coverScopes = response.data;
      } catch (error) {
        this.error(error.message);
      }
    },

    async getEndorsementOptions() {
      try {
        const response = await marineService.getEndorsementOptions();
        this.endorsementOptions = response.data;
      } catch (error) {
        this.error(error.message);
      }
    },

    async getCertificateTypes() {
      try {
        const response = await marineService.getCertificateTypes();
        this.certificateTypes = response.data;
      } catch (error) {
        this.error(error.message);
      }
    },

    async getCurrency() {
      try {
        const response = await marineService.getCurrency();
        this.currencyRates = response.data;
      } catch (error) {
        this.error(error.message);
      }
    },

    async getOccupations() {
      try {
        const response = await marineService.getOccupation();
        this.occupations = response.data;
      } catch (error) {
        this.error(error.message);
      }
    },

    async getIndustries() {
      try {
        const response = await marineService.getIndustries();
        this.industries = response.data;
      } catch (error) {
        this.error(error.message);
      }
    },

    async getAdditionalClauses(coverType) {
      this.additionalClauses = [];
      try {
        const response = await marineService.getAdditionalClauses(coverType);
        this.additionalClauses = response.data;
      } catch (error) {
        this.error(error.message);
      }
    },

    async getAdditionalClausesOpenCover(coverType) {
      try {
        const response = await marineService.getAdditionalClauses(coverType);
        let result = response.data;
        if (this.additionalClauses.length !== 0) {
          for (let i = 0; i < this.additionalClauses.length; i++) {
            for (let j = 0; j < result.length; j++) {
              if (this.additionalClauses[i].id == result[j].id) {
                result.splice(j, 1);
              }
            }
          }
          this.additionalClauses = [...this.additionalClauses, ...result];
        } else {
          this.additionalClauses = response.data;
        }
      } catch (error) {
        this.error(error.message);
      }
    },

    resetAdditionalClausesOpenCover() {
      this.additionalClauses = [];
    },
    resetData() {
      this.marineData = {};
      this.marineMetaData = {};
    },

    saveStep(data, metaData) {
      this.marineData = { ...this.marineData, ...data };
      this.marineMetaData = { ...this.marineMetaData, ...metaData };
    },

    async uploadDocument(document) {
      this.loading = true;
      // console.log('--- Uploading Document ---')
      try {
        const response = await marineService.uploadDocument(document);
        if (!response) {
          alert("An Error Occured Uploading Document");
          return;
        }

        // console.log('--- Done Uploading Document ---')

        return response.data;
      } catch (error) {
        this.error("An Error Occured Uploading Document");
        return;
      }
    },

    async getDocument(fileName) {
      this.additionalClauses = [];
      try {
        const response = await marineService.getDocument(fileName);

        //console.log(response.data)
        return response.data;
      } catch (error) {
        this.error(error.message);
      }
    },

    async buyMarine(payload) {
      this.loading = true;
      try {
        const response =
          mainStore.user.role == "Broker"
            ? await marineService.buyMarine(
                payload,
                mainStore.user.broker,
                mainStore.user.brokerId
              )
            : mainStore.user.role == "Agent"
            ? await marineService.buyMarineAgent(
                payload,
                mainStore.user.broker,
                mainStore.user.brokerId
              )
            : mainStore.user.role == "Customer" &&
              (await marineService.buyMarineCustomer(payload));

        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;

        return response.data;
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async buyAdditionalMarine(payload) {
      this.loading = true;
      try {
        const response =
          mainStore.user.role == "Broker"
            ? await marineService.buyAdditionalMarine(
                payload,
                mainStore.user.broker,
                mainStore.user.brokerId
              )
            : await marineService.buyAdditionalMarineAgent(
                payload,
                mainStore.user.broker,
                mainStore.user.brokerId
              );

        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;

        return response.data;
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async getBookedBusinesses() {
      this.pageLoading = true;
      try {
        const response = await marineService.getBookedBusinesses(
          mainStore.user.brokerId
        );
        this.pageLoading = false;
        this.bookedBusinesses = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async getAgentBookedBusinesses() {
      this.pageLoading = true;
      try {
        const response = await marineService.getAgentBookedBusinesses(
          mainStore.user.brokerId
        );
        this.pageLoading = false;
        this.bookedBusinesses = response.data;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
      }
    },

    async getSingleBusiness(id) {
      this.pageLoading = true;
      try {
        const response = await marineService.getMarineDetails(id);
        this.singleBusiness = response.data;
        this.pageLoading = false;
      } catch (error) {
        this.pageLoading = false;
        this.error(error.message);
        this.pageLoading = false;
      }
    },

    async makePayment(payload) {
      this.loading = true;
      try {
        const response = await marineService.makePayment(
          payload,
          mainStore.user.broker,
          mainStore.user.brokerId
        );

        if (!response.data.isSuccessful) {
          this.loading = false;
          this.error(response.data.message);
          return;
        }
        this.loading = false;

        await this.success("Payment Made Successfully");
        return response.data;
      } catch (error) {
        this.loading = false;
        this.error(error.message);
      }
    },

    async getClient(id) {
      this.componentLoading = true;
      this.componentLoadingMessage = "Fetching Customer Details ...";
      try {
        const response = await marineService.getClient(id);
        this.marineData = { ...response.data };
        delete this.marineData["insuredId"];
        delete this.marineData["policyNo"];
        this.componentLoading = false;
        this.componentLoadingMessage = "";
        router.push({
          name:
            mainStore.user.role == "Broker" ? "BuyMarine" : "AgentBuyMarine",
        });
      } catch (error) {
        this.componentLoading = false;
        this.error(error.message);
        this.componentLoading = false;
        this.componentLoadingMessage = "";
      }
    },
  },

  getters: {
    unpaidCertiifcates: (state) =>
      state.singleBusiness.additionalMarine.filter((certificate) => {
        return (
          certificate.tranrsactionreference == "" ||
          certificate.tranrsactionreference == null
        );
      }),
  },
});
