import { defineStore } from "pinia";
import authService from "../services/auth.service";
import { useMainStore } from "./main";
import Swal from "sweetalert2";
import { $Session } from "../helpers/storage";
import router from "../router";

const mainStore = useMainStore();

export const useAuthStore = defineStore("authentication", {
  state: () => {
    return {
      loading: false,
      pageLoading: false,
      status: {
        type: "",
        class: "",
        message: "",
      },

      otpStep: 1,
      otpEmail: "",
    };
  },

  actions: {
    authError(message) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
        confirmButtonColor: "#990100",
        footer: '<a href="">Report issue</a>',
      });
    },

    async authSuccess(message) {
      await Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        confirmButtonColor: "#990100",
      });
    },

    async login(payload) {
      this.loading = true;
      try {
        const response = await authService.authenticate(payload);

        if (!response.data.isSuccessful) {
          this.loading = false;
          this.authError("Incorrect Email Or Password");
          return;
        }

        mainStore.isLoggedIn = true;
        mainStore.user = response.data;
        $Session.setItem("user", JSON.stringify(response.data));
        $Session.setItem("access-token", response.data.token);
        this.loading = false;
        return "success";
      } catch (error) {
        this.loading = false;
        this.authError(error.message);
      }
    },

    async customerLogin(payload) {
      //   this.loading = true;
      try {
        const response = await authService.authenticate(payload);
        if (!response.data.isSuccessful) {
          this.loading = false;
          this.authError("Incorrect Email Or Password");
          return;
        }

        // mainStore.isLoggedIn = true;
        mainStore.user = response.data;
        mainStore.user.role = "Customer";
        $Session.setItem("user", JSON.stringify(response.data));
        $Session.setItem("access-token", response.data.token);
        this.loading = false;
        return "success";
      } catch (error) {
        this.loading = false;
        this.authError(error.message);
      }
    },

    async register(payload) {
      this.loading = true;
      try {
        const response = await authService.authenticate(payload);

        if (!response.data.isSuccessful) {
          this.loading = false;
          this.authError("Incorrect Username Or Password");
          return;
        }

        mainStore.isLoggedIn = true;
        mainStore.user.data = response.data;
        this.loading = false;
        return "success";
      } catch (error) {
        this.loading = false;
        this.authError(error.message);
      }
    },

    async forgotPassword(payload) {
      this.loading = true;
      try {
        const response = await authService.sendOtp(payload);
        this.otpEmail = payload.email;
        if (!response) {
          this.loading = false;
          this.authError("Invalid Email Address");
          return;
        }

        this.otpStep = 2;
        this.loading = false;
        this.authSuccess("An OTP has been sent to your email address");
        return "success";
      } catch (error) {
        this.loading = false;
        this.authError(error.message);
      }
    },

    async changePassword(payload) {
      this.loading = true;
      if (payload.password === payload.confirmPassword) {
        try {
          const response = await authService.changePassword(payload);
          if (!response.data.isSuccessful) {
            this.loading = false;
            this.authError(response.data.message);
            return;
          }

          this.loading = false;
          await this.authSuccess("Your Password Has Successfully Been Changed");
          this.otpStep = 1;
          router.push({ name: "Login" });

          return "success";
        } catch (error) {
          this.loading = false;
          this.authError(error.message);
        }
      } else {
        alert("Passwords Do Not Match");
        this.loading = false;
      }
    },

    logout() {
      $Session.clear();
      window.location = "/login";
    },
  },
});
