import $axios from '../plugins/axios';

export default class {
    static authenticate = async (payload) => {
        return await $axios().post('/authenticate', payload);
    };

    static registration = async ({ email, password, userName }) => {
        return await $axios().post('auth/register', { email, password, username: userName });
    };

    static sendOtp = async ({ email }) => {
        return await $axios().post('/resendOTP', { email });
    };

    static changePassword = async (payload) => {
        return await $axios().post('/changePassword', payload);
    };
}

