import $axios from '../plugins/axios';

export default class {
    static getUsers = async () => {
        return await $axios().get('/getAllUser');
    };

    static getBrokers = async () => {
        return await $axios().get('/GetBroker');
    };

    static createUser = async (payload, submittedBy) => {
        return await $axios().post('/registerUser', { ...payload, submittedBy });
    };

    static getUserById = async (id) => {
        return await $axios().get(`/getUserById${id}`);
    };

    static updateUser = async (payload, submittedBy) => {
        return await $axios().post('/updateUser', { ...payload, submittedBy });
    };


}

